import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import AuthService from "../services/auth.service";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        pageTitle: "Home",
        requiresAuth: true,
      },
    },
    {
      path: "/second-page",
      name: "second-page",
      component: () => import("@/views/SecondPage.vue"),
      meta: {
        pageTitle: "Second Page",
      },
    },
    {
      path: "/packages-old",
      name: "packages-old",
      component: () => import("@/views/packages-old/Packages.vue"),
      meta: {
        pageTitle: "Extension Packages",
      },
    },
    {
      path: "/imprint",
      name: "imprint",
      component: () => import("@/views/Imprint.vue"),
      meta: {
        pageTitle: "Imprint",
      },
    },
    {
      path: "/dataprivacy",
      name: "dataprivacy",
      component: () => import("@/views/DataPrivacy.vue"),
      meta: {
        pageTitle: "Data Privacy",
      },
    },
    // {
    //   path: "/invoices",
    //   name: "invoices",
    //   component: () => import("@/views/InvoiceList.vue"),
    //   meta: {
    //     pageTitle: "Invoices",
    //   },
    // },
    {
      path: "/account-setting",
      name: "account-setting",
      component: () => import("@/views/account-setting/AccountSetting.vue"),
      meta: {
        pageTitle: "Account Settings",
        requiresAuth: true,
      },
    },
    {
      path: "/faq",
      name: "faq",
      component: () => import("@/views/faq/Faq.vue"),
      meta: {
        pageTitle: "FAQ",
        requiresAuth: true,
      },
    },
    {
      path: "/apps/invoice/preview/:id",
      name: "apps-invoice-preview",
      component: () => import("@/views/InvoicePreview.vue"),
    },
    {
      path: "/2d-to-3d",
      name: "2d-to-3d",
      component: () => import("@/views/2DTo3D.vue"),
      meta: {
        pageTitle: "2D to 3D Reconstruction",
        requiresAuth: true,
      },
    },
    {
      path: "/cloud-storage",
      name: "cloud-storage",
      component: () => import("@/views/CloudStorage.vue"),
      meta: {
        pageTitle: "Cloud Storage",
        requiresAuth: true,
      },
    },
    {
      path: "/case-lists",
      name: "case-list",
      component: () => import("@/views/case-lists/Index.vue"),
      meta: {
        pageTitle: "Case List",
        requiresAuth: true,
      },
    },
    {
      path: "/",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
        requiresAuth: true,
      },
    },
    {
      path: "/api-keys",
      name: "api-keys",
      component: () => import("@/views/api-keys/Index.vue"),
      meta: {
        pageTitle: "API Keys",
        permission: "api-keys",
        requiresAuth: true,
      },
    },
    {
      path: "/api-keys/create",
      name: "api-keys-create",
      component: () => import("@/views/api-keys/Create.vue"),
      meta: {
        pageTitle: "Create API Key",
        permission: "api-keys",
        requiresAuth: true,
      },
    },
    {
      path: "/api-keys/:id/edit",
      name: "api-keys/edit",
      component: () => import("@/views/api-keys/Edit.vue"),
      meta: {
        pageTitle: "Edit API Key",
        permission: "api-keys",
        requiresAuth: true,
      },
    },
    {
      path: "/customers",
      name: "customers",
      component: () => import("@/views/customers/Index.vue"),
      meta: {
        pageTitle: "Customers",
        permission: "company",
        requiresAuth: true,
      },
    },
    {
      path: "/customers/create",
      name: "customers-create",
      component: () => import("@/views/customers/Create.vue"),
      meta: {
        pageTitle: "Create Customer",
        permission: "company",
        requiresAuth: true,
      },
    },
    {
      path: "/customers/:id/edit",
      name: "customers/edit",
      component: () => import("@/views/customers/Edit.vue"),
      meta: {
        pageTitle: "Edit Customer",
        permission: "company",
        requiresAuth: true,
      },
    },
    {
      path: "/global-configuration",
      name: "global-configuration",
      component: () => import("@/views/global-configuration/Index.vue"),
      meta: {
        pageTitle: "Global Configuration",
        permission: "global-configuration",
        requiresAuth: true,
      },
    },
    {
      path: "/invoices",
      name: "invoices",
      component: () => import("@/views/invoices/Index.vue"),
      meta: {
        pageTitle: "Invoices",
        permission: "invoice",
        requiresAuth: true,
      },
    },
    {
      path: "/invoices/create",
      name: "invoices-create",
      component: () => import("@/views/invoices/Create.vue"),
      meta: {
        pageTitle: "Create Invoice",
        permission: "invoice",
        requiresAuth: true,
      },
    },
    {
      path: "/invoices/:id/edit",
      name: "invoices/edit",
      component: () => import("@/views/invoices/Edit.vue"),
      meta: {
        pageTitle: "Edit Invoice",
        permission: "invoice",
        requiresAuth: true,
      },
    },
    {
      path: "/invoices/:id/show",
      name: "invoices/show",
      component: () => import("@/views/invoices/Show.vue"),
      meta: {
        pageTitle: "Show Invoice",
        permission: "invoice",
        requiresAuth: true,
      },
    },
    {
      path: "/coupon-codes",
      name: "coupon-codes",
      component: () => import("@/views/coupon-codes/Index.vue"),
      meta: {
        pageTitle: "Coupon Codes",
        permission: "coupons",
        requiresAuth: true,
      },
    },
    {
      path: "/coupon-codes/create",
      name: "coupon-codes-create",
      component: () => import("@/views/coupon-codes/Create.vue"),
      meta: {
        pageTitle: "Create Coupon Code",
        permission: "coupons",
        requiresAuth: true,
      },
    },
    {
      path: "/coupon-codes/:id/edit",
      name: "coupon-codes/edit",
      component: () => import("@/views/coupon-codes/Edit.vue"),
      meta: {
        pageTitle: "Edit Coupon Code",
        permission: "coupons",
        requiresAuth: true,
      },
    },
    {
      path: "/coupon-codes/:id/show",
      name: "coupon-codes/show",
      component: () => import("@/views/coupon-codes/Show.vue"),
      meta: {
        pageTitle: "Show Coupon Code",
        permission: "coupons",
        requiresAuth: true,
      },
    },
    {
      path: "/packages",
      name: "packages",
      component: () => import("@/views/packages/Index.vue"),
      meta: {
        pageTitle: "Credits Packages",
        permission: "packages",
        requiresAuth: true,
      },
    },
    {
      path: "/packages/create",
      name: "packages-create",
      component: () => import("@/views/packages/Create.vue"),
      meta: {
        pageTitle: "Create Credits Package",
        permission: "packages",
        requiresAuth: true,
      },
    },
    {
      path: "/packages/:id/edit",
      name: "packages/edit",
      component: () => import("@/views/packages/Edit.vue"),
      meta: {
        pageTitle: "Edit Credits Package",
        permission: "packages",
        requiresAuth: true,
      },
    },
    {
      path: "/packages/:id/show",
      name: "packages/show",
      component: () => import("@/views/packages/Show.vue"),
      meta: {
        pageTitle: "Show Credits Package",
        permission: "packages",
        requiresAuth: true,
      },
    },
    {
      path: "/invoice-reminder-levels",
      name: "invoice-reminder-levels",
      component: () => import("@/views/invoice-reminder-levels/Index.vue"),
      meta: {
        pageTitle: "Invoice Reminder Levels",
        permission: "invoice-reminder-levels",
        requiresAuth: true,
      },
    },
    {
      path: "/invoice-reminder-levels/create",
      name: "invoice-reminder-levels-create",
      component: () => import("@/views/invoice-reminder-levels/Create.vue"),
      meta: {
        pageTitle: "Create Invoice Reminder Level",
        permission: "invoice-reminder-levels",
        requiresAuth: true,
      },
    },
    {
      path: "/invoice-reminder-levels/:id/edit",
      name: "invoice-reminder-levels/edit",
      component: () => import("@/views/invoice-reminder-levels/Edit.vue"),
      meta: {
        pageTitle: "Edit Invoice Reminder Level",
        permission: "invoice-reminder-levels",
        requiresAuth: true,
      },
    },
    {
      path: "/news",
      name: "news",
      component: () => import("@/views/news/Index.vue"),
      meta: {
        pageTitle: "News",
        permission: "news",
        requiresAuth: true,
      },
    },
    {
      path: "/news/create",
      name: "news-create",
      component: () => import("@/views/news/Create.vue"),
      meta: {
        pageTitle: "Create News",
        permission: "news",
        requiresAuth: true,
      },
    },
    {
      path: "/news/:id/edit",
      name: "news/edit",
      component: () => import("@/views/news/Edit.vue"),
      meta: {
        pageTitle: "Edit News",
        permission: "news",
        requiresAuth: true,
      },
    },
    {
      path: "/news/:id/show",
      name: "news/show",
      component: () => import("@/views/news/Show.vue"),
      meta: {
        pageTitle: "Show News",
        permission: "news",
        requiresAuth: true,
      },
    },
    {
      path: "/users",
      name: "users",
      component: () => import("@/views/users/Index.vue"),
      meta: {
        pageTitle: "Users",
        permission: "users",
        requiresAuth: true,
      },
    },
    {
      path: "/users/create",
      name: "users-create",
      component: () => import("@/views/users/Create.vue"),
      meta: {
        pageTitle: "Create User",
        permission: "users",
        requiresAuth: true,
      },
    },
    {
      path: "/users/:id/edit",
      name: "users/edit",
      component: () => import("@/views/users/Edit.vue"),
      meta: {
        pageTitle: "Edit User",
        permission: "users",
        requiresAuth: true,
      },
    },
    {
      path: "/permissions",
      name: "permissions",
      component: () => import("@/views/permissions/Index.vue"),
      meta: {
        pageTitle: "Permissions",
        permission: "permissions",
        requiresAuth: true,
      },
    },
    {
      path: "/permissions/create",
      name: "permissions-create",
      component: () => import("@/views/permissions/Create.vue"),
      meta: {
        pageTitle: "Create Permission",
        permission: "permissions",
        requiresAuth: true,
      },
    },
    {
      path: "/permissions/:id/edit",
      name: "permissions/edit",
      component: () => import("@/views/permissions/Edit.vue"),
      meta: {
        pageTitle: "Edit Permission",
        permission: "permissions",
        requiresAuth: true,
      },
    },
    {
      path: "/document-assignment",
      name: "document-assignment",
      component: () => import("@/views/document-assignment/Index.vue"),
      meta: {
        pageTitle: "Document Assignment",
        permission: "document-assignment",
        requiresAuth: true,
      },
    },
    {
      path: "/mail-template-assignment",
      name: "mail-template-assignment",
      component: () => import("@/views/mail-template-assignment/Index.vue"),
      meta: {
        pageTitle: "Mail Template Assignment",
        permission: "mail-template-assignment",
        requiresAuth: true,
      },
    },
    {
      path: "/roles",
      name: "roles",
      component: () => import("@/views/roles/Index.vue"),
      meta: {
        pageTitle: "Roles",
        permission: "roles",
        requiresAuth: true,
      },
    },
    {
      path: "/roles/create",
      name: "roles-create",
      component: () => import("@/views/roles/Create.vue"),
      meta: {
        pageTitle: "Create Role",
        permission: "roles",
        requiresAuth: true,
      },
    },
    {
      path: "/roles/:id/edit",
      name: "roles/edit",
      component: () => import("@/views/roles/Edit.vue"),
      meta: {
        pageTitle: "Edit Role",
        permission: "roles",
        requiresAuth: true,
      },
    },
    {
      path: "/settings",
      name: "settings",
      component: () => import("@/views/settings/Setting.vue"),
      meta: {
        pageTitle: "Settings",
        requiresAuth: true,
      },
    },
    {
      path: "/my-cases",
      name: "my-cases",
      component: () => import("@/views/my-cases/Index.vue"),
      meta: {
        pageTitle: "My Cases",
        requiresAuth: true,
      },
    },
    {
      path: "/tickets",
      name: "tickets",
      component: () => import("@/views/tickets/Index.vue"),
      meta: {
        pageTitle: "Tickets",
        requiresAuth: true,
      },
    },
    {
      path: "/tickets/create",
      name: "tickets-create",
      component: () => import("@/views/tickets/Create.vue"),
      meta: {
        pageTitle: "Create Ticket",
        requiresAuth: true,
      },
    },
    {
      path: "/tickets/:id/edit",
      name: "tickets-edit",
      component: () => import("@/views/tickets/Edit.vue"),
      meta: {
        pageTitle: "Edit Ticket",
        requiresAuth: true,
      },
    },
    {
      path: "*",
      redirect: "error-404",
      requiresAuth: true,
    },
  ],
});

// router.beforeResolve((to, from, next) => {
//   // If this isn't an initial page load.
//   if (to.name) {
//     // Start the route progress bar.
//     NProgress.start();
//   }

//   next()

// })

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");

  // Check if the route requires authentication and if token doesn't exist
  if (to.meta.requiresAuth && !token) {
    // Redirect to login page
    next("/");
  } else {
    // Continue to the route
    next();
  }
});

const DEFAULT_TITLE = "DentalTwin - Your Dentist as a Service";
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });

  // Complete the animation of the route progress bar.
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
  NProgress.done();
});

export default router;
