import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";

class NotificationService {
  showInfo(msg) {
    Vue.$toast(
      {
        component: ToastificationContent,
        props: {
          title: "Success",
          text: msg,
          variant: "success",
          icon: "BellIcon",
        },
      },
      {
        position: "bottom-right",
        timeout: 5000, // Set timeout to 5 seconds
      }
    );
  }

  showError(msg) {
    if (typeof msg == "object") {
      msg = msg.message;
    }

    Vue.$toast(
      {
        component: ToastificationContent,
        props: {
          title: "Error",
          text: msg,
          variant: "danger",
          icon: "BellIcon",
        },
      },
      {
        position: "bottom-right",
        timeout: 5000, // Set timeout to 5 seconds
      }
    );
  }
}

export default new NotificationService();
