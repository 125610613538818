export default {
    namespaced: true,
    state: {},
    actions: {
        list({}, params) {
            return this.$apiService.get("/ticket/comments", params);
        },
        create({}, payload) {
            return this.$apiService.post("/ticket/comments", payload);
        },
        downloadAttachment({}, id) {
            return this.$apiService.get(
                `ticket/comments/download-attachment/${id}`
            );
        },
        removeAttachment({}, id) {
            return this.$apiService.get(
                `ticket/comments/remove-attachment/${id}`
            );
        },
        // answerForm({ ticketCommentId, data }) {
        //     return this.$apiService.post(
        //         `ticket/comments/answer-form/${ticketCommentId}`,
        //         data
        //     );
        // },
    },
}