import restClient from "./api";
import TokenService from "./token.service";
import { mustGetEnv } from "@/utils/env";

let baseURLAuth = mustGetEnv("VUE_APP_AUTHSERVICE_BASEURL");

class AuthService {
  login(email, password) {
    console.log(email);
    return restClient
      .post(baseURLAuth + "/login", {
        mail: email,
        password: password,
      })
      .then((response) => {
        console.log(response);
        if (typeof response.data.token !== "undefined") {
          TokenService.setUser(response.data);

          var json = {};
          localStorage.setItem("loggedin_user", JSON.stringify());
        }
        return response.data;
      });
  }

  logout() {
    TokenService.removeUser();
  }

  isLoggedIn() {
    const user = TokenService.getUser();
    return user ? true : false;
  }

  register(email, password) {
    return restClient.post(baseURLAuth + "/create-user", {
      mail: email,
      password: password,
    });
  }
}

export default new AuthService();
