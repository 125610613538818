export default {
  namespaced: true,
  state: {
    countryCredits: [],
    count: 0,
    count: 0,
  },
  getters: {
    countryCredits: (state) => state.countryCredits,
    count: (state) => state.count,
  },
  mutations: {
    countryCredits: (state, payload) => (state.countryCredits = payload),
    count: (state, payload) => (state.count = payload),
  },
  actions: {
    list({ commit }, queryParams) {
      return this.$apiService
        .get("/country-credits", {
          params: queryParams,
        })
        .then((res) => {
          commit("countryCredits", res?.data?.data ?? []);
          commit("count", res?.data?.count ?? 0);
          return res;
        });
    },
    create({}, payload) {
      return this.$apiService.post("/country-credits", payload);
    },
    show({}, id) {
      return this.$apiService.get(`/country-credits/${id}`);
    },
    update({}, { id, data }) {
      return this.$apiService.put(`/country-credits/${id}`, data);
    },
    destroy({}, id) {
      return this.$apiService.delete(`/country-credits/${id}`);
    },
  },
};
