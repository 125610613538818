import axios from "axios";
import router from "../router";
import store from "../store";
import { mustGetEnv } from "@/utils/env";

let mailApiService = axios.create({
  baseURL: mustGetEnv("VUE_APP_MAILSERVICE_BASEURL"),
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});

mailApiService.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});
mailApiService.interceptors.response.use(
  function (response) {
    store.commit("isLoading", false);
    return response;
  },
  async function (error) {
    let parsedResponse = JSON.parse(await error.response.data) ?? {};
    store.dispatch(
      "showResponse",
      {
        type: "error",
        message: parsedResponse?.message ?? {},
        errors: parsedResponse?.errors ?? {},
      },
      {
        root: true,
      }
    );

    return Promise.reject(error);
  }
);

export default mailApiService;
