export default {
  namespaced: true,
  state: {
    packages: [],
    count: 0,
  },
  getters: {
    packages: (state) => state.packages,
    count: (state) => state.count,
  },
  mutations: {
    packages: (state, payload) => (state.packages = payload),
    count: (state, payload) => (state.count = payload),
  },
  actions: {
    list({ commit }, queryParams) {
      return this.$apiService
        .get("/packages", {
          params: queryParams,
        })
        .then((res) => {
          commit("packages", res?.data?.data ?? []);
          commit("count", res?.data?.count ?? 0);
          return res;
        });
    },
    create({}, payload) {
      return this.$apiService.post("/packages", payload);
    },
    show({}, id) {
      return this.$apiService.get(`/packages/${id}`);
    },
    update({}, { id, data }) {
      return this.$apiService.put(`/packages/${id}`, data);
    },
    destroy({}, id) {
      return this.$apiService.delete(`/packages/${id}`);
    },
  },
};
