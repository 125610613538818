export default {
  namespaced: true,
  state: {
    countryPackages: [],
    count: 0,
    count: 0,
  },
  getters: {
    countryPackages: (state) => state.countryPackages,
    count: (state) => state.count,
  },
  mutations: {
    countryPackages: (state, payload) => (state.countryPackages = payload),
    count: (state, payload) => (state.count = payload),
  },
  actions: {
    list({ commit }, queryParams) {
      return this.$apiService
        .get("country-packages", {
          params: queryParams,
        })
        .then((res) => {
          commit("countryPackages", res?.data?.data ?? []);
          commit("count", res?.data?.count ?? 0);
          return res;
        });
    },
    create({}, payload) {
      return this.$apiService.post("country-packages", payload);
    },
    show({}, id) {
      return this.$apiService.get(`country-packages/${id}`);
    },
    update({}, { id, data }) {
      return this.$apiService.put(`country-packages/${id}`, data);
    },
    destroy({}, id) {
      return this.$apiService.delete(`country-packages/${id}`);
    },
  },
};
