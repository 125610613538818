export default {
  namespaced: true,
  state: {
    customers: [],
    count: 0,
  },
  getters: {
    customers: (state) => state.customers,
    count: (state) => state.count,
  },
  mutations: {
    customers: (state, payload) => (state.customers = payload),
    count: (state, payload) => (state.count = payload),
  },
  actions: {
    list({ commit }, queryParams) {
      return this.$apiService
        .get("/companies", {
          params: queryParams,
        })
        .then((res) => {
          commit("customers", res?.data?.data ?? []);
          commit("count", res?.data?.count ?? 0);
          return res;
        });
    },
    create({}, payload) {
      return this.$apiService.post("/companies", payload);
    },
    show({}, id) {
      return this.$apiService.get(`/companies/${id}`);
    },
    update({}, { id, data }) {
      return this.$apiService.put(`/companies/${id}`, data);
    },
    destroy({}, id) {
      return this.$apiService.delete(`/companies/${id}`);
    },
    importCsv({}, payload) {
      return this.$apiService.post("/companies-import", payload);
    },
  },
};
