import axios from "axios";
import store from "../store";
import NotificationService from "@/services/notification.service";
import router from "../router";
import { mustGetEnv } from "@/utils/env";

let apiService = axios.create({
  baseURL: `${mustGetEnv("VUE_APP_BACKEND_BASEURL")}/api`,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});

apiService.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});
apiService.interceptors.response.use(
  function (response) {
    store.commit("isLoading", false);
    if (
      response?.status == 200 &&
      (response?.config?.url === "/login" ||
        response?.config?.url === "/refresh-token")
    ) {
      localStorage.setItem("token", response?.data?.token);
      localStorage.setItem("refresh_token", response?.data?.refresh_token);
      localStorage.setItem(
        "roles",
        JSON.stringify(response?.data?.token_info?.roles ?? [])
      );
      localStorage.setItem(
        "permissions",
        JSON.stringify(response?.data?.token_info?.permissions ?? [])
      );
      localStorage.setItem(
        "scopes",
        JSON.stringify(response?.data?.token_info?.scopes ?? [])
      );
      localStorage.setItem("user_id", response?.data?.token_info?.user_id);
      localStorage.setItem(
        "types",
        JSON.stringify(response?.data?.token_info?.types ?? {})
      );
    }
    const responseData = response?.data;

    if (responseData) {
      const { msg, message } = responseData;

      if (msg || message) {
        NotificationService.showInfo(msg || message);
      }
    }
    return response;
  },
  function (error) {
    store.commit("isLoading", false);
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 419)
    ) {
      // Clear the token and other user information
      localStorage.clear();
      NotificationService.showError("You need to relogin");
      // Redirect to login page
      router.push("/");
    }
    // if (
    //   (error?.response?.status === 401 || error?.response?.status === 419) &&
    //   error?.config?.url === "/login"
    // ) {
    //   store.dispatch("auth/refreshToken", {
    //     refresh_token: localStorage.getItem("refresh_token"),
    //   });
    // }
    const responseData = error?.response?.data;

    if (responseData) {
      const { msg, message, errors } = responseData;

      if (msg || message) {
        if (error.response.status != 419) {
          store.dispatch(
            "showResponse",
            {
              type: "error",
              message: msg || message,
              errors: errors || {},
            },
            {
              root: true,
            }
          );
        }
      }
    }
    return Promise.reject(error);
  }
);

export default apiService;
