import axios from "axios";
import router from "../router";
import store from "../store";
import { mustGetEnv } from "@/utils/env";

let documentApiService = axios.create({
  baseURL: mustGetEnv("VUE_APP_DOCUMENTSERVICE_BASEURL"),
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});

documentApiService.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});
documentApiService.interceptors.response.use(
  function (response) {
    store.commit("isLoading", false);
    if (
      response?.status == 200 &&
      (response?.config?.url === "/login" ||
        response?.config?.url === "/refresh-token")
    ) {
      localStorage.setItem("token", response?.data?.token);
      localStorage.setItem("refresh_token", response?.data?.refresh_token);
      localStorage.setItem(
        "roles",
        JSON.stringify(response?.data?.token_info?.roles ?? [])
      );
      localStorage.setItem(
        "permissions",
        JSON.stringify(response?.data?.token_info?.permissions ?? [])
      );
      localStorage.setItem(
        "scopes",
        JSON.stringify(response?.data?.token_info?.scopes ?? [])
      );
      localStorage.setItem("user_id", response?.data?.token_info?.user_id);
      localStorage.setItem(
        "types",
        JSON.stringify(response?.data?.token_info?.types ?? {})
      );
    }
    return response;
  },
  async function (error) {
    store.commit("isLoading", false);
    if (error.response.status === 401 || error.response.status === 419) {
      // Clear the token and other user information
      localStorage.clear();
      // Redirect to login page
      router.push("/");
    }

    let jsonData = await error.response?.data?.text();
    console.log(jsonData);
    let parsedResponse = {};
    if (jsonData) {
      parsedResponse = JSON.parse(jsonData) ?? {};
    }
    store.dispatch(
      "showResponse",
      {
        type: "error",
        message: parsedResponse?.message ?? {},
        errors: parsedResponse?.errors ?? {},
      },
      {
        root: true,
      }
    );

    return Promise.reject(error);
  }
);

export default documentApiService;
