class TokenService 
{
    constructor()
    {
      this.tokenChannel = new BroadcastChannel('token_channel');
    }
    

    broadcastToken()
    {
      const user=this.getUser();
      this.tokenChannel.postMessage({token: user.token,refresh_token: user.refresh_token});
    }

    getLocalRefreshToken() {
      const user = JSON.parse(sessionStorage.getItem("user"));
      return user?.refresh_token;
    }
    
    getLocalAccessToken() {
      const user = JSON.parse(sessionStorage.getItem("user"));
      return user?.token;
    }

    getLocalUserId() {
      const user = JSON.parse(sessionStorage.getItem("user"));
      return user?.token_info?.user_id;
    }

    getLocalUserLabels() {
      const user = JSON.parse(sessionStorage.getItem("user"));
      return user?.token_info?.roles;
    }

    getLocalUser() {
      const user = JSON.parse(sessionStorage.getItem("user"));
      return user?.token_info?.user;
    }

    updateLocalAccessToken(token) {
      let user = JSON.parse(sessionStorage.getItem("user"));
      user.token = token;
      sessionStorage.setItem("user", JSON.stringify(user));
      this.broadcastToken();
    }

    updateLocalRefreshToken(token) {
      let user = JSON.parse(sessionStorage.getItem("user"));
      user.refresh_token = token;
      sessionStorage.setItem("user", JSON.stringify(user));
    }

    getUser() {
      return JSON.parse(sessionStorage.getItem("user"));
    }

    setUser(user) 
    {
      sessionStorage.setItem("user", JSON.stringify(user));
      this.broadcastToken();
    }
    
    removeUser() 
    {
      sessionStorage.removeItem("user");
      tokenChannel.postMessage({token: null});
    }
  }
  export default new TokenService();