import store from "../store";

const hasRole = (roleName) => {
    let roles = JSON.parse(localStorage.getItem("roles") ?? "[]");
    return roles.indexOf(roleName) !== -1;
};

const formatter = (
    number,
    language = "en",
    currency = "EUR",
    simpleNumber,
    minimumFractionDigits = 2,
    maximumFractionDigits = 20
) => {
    const languages = {
        en: "GB",
        de: "DE",
        fr: "FR",
        es: "ES"
    };
    let formattedNumber = number;
    try {
        let obj = {};
        if (!simpleNumber) {
            obj = {
                style: "currency",
                currency: currency,
            };
        }
        formattedNumber = new Intl.NumberFormat(
            language + "-" + languages[language],
            {
                ...obj,
                minimumFractionDigits: minimumFractionDigits,
                maximumFractionDigits: maximumFractionDigits,
            }
        ).format(number);
    } catch (e) {
        console.log(e);
    } finally {
        return formattedNumber;
    }
};

const dateFormatter = (date, language = "en") => {
    let formattedDate = "";
    try {
        const dateTemp = new Date(date);
        const formatDate = (dateTemp, lang) => {
            const dateString = dateTemp.toLocaleDateString(lang);
            const timeString = dateTemp.toLocaleTimeString(lang);
            return date.includes(":")
                ? `${dateString} ${timeString.replace(/\//g, ".")}`
                : dateString;
        };

        if (date && !isNaN(dateTemp)) {
            formattedDate =
                language === "de"
                    ? formatDate(dateTemp, "de-DE")
                    : formatDate(dateTemp, "en-US");
        }
        // else {
        //     throw new Error('Invalid date format');
        // }
    } catch (e) {
        console.error(e);
    } finally {
        return formattedDate;
    }
};

const timeAgo = (date)  => {
    const now = new Date();
    const createdAt = new Date(date);
    const seconds = Math.floor((now - createdAt) / 1000);

    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
        return interval + " years ago";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
        return interval + " months ago";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
        return interval + " days ago";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
        return interval + " hours ago";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
        return interval + " minutes ago";
    }
    return "just now";
};

const adjustForDST = (date) => {
    // Get the current time zone offset in minutes
    var standardOffset = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();

    // Check if daylight saving time is in effect
    var isDST = date.getTimezoneOffset() < standardOffset;

    // Adjust the date for daylight saving time
    var adjustedDate = new Date(
        date.getTime() + (isDST ? -1 : 1) * 60 * 60 * 1000
    );

    return adjustedDate;
};

/**
 * converts dateTime into local browser dateTime
 * @param {date} date to be converted
 * @returns converted dateTime in local browser time
 */
const localTime = (date) => {
    // Get the current time zone offset in minutes
    const utcDate = new Date(date);
    const offset = utcDate.getTimezoneOffset() * 60000; // Offset in milliseconds
    const localTimestamp = utcDate.getTime() - offset;
    const localDate = new Date(localTimestamp);
    return localDate.toLocaleString();
};

/**
 * converts time into local browser time
 * @param {date} date to be converted
 * @returns converted time in local browser time
 */
const onlyLocalTime = (time) => {
    const utcTime = new Date(`2022-01-01T${time}Z`);
    const hours = utcTime.getHours();
    const minutes = utcTime.getMinutes();
    const seconds = utcTime.getSeconds();

    // Formatting hours, minutes, and seconds to ensure leading zeros if necessary
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    // Concatenating hours, minutes, and seconds
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

/**
 * converts dashed string to capitalizes string without dashes
 * @param {string} string to be converted
 * @returns capitalized string without dashes
 */
const dashedToRegularFormat = (string) => {
    try {
        // Split the input string by dashes
        const words = string.split("-");

        // Capitalize each word and join them with spaces
        const capitalizedWords = words.map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

        // Join the capitalized words to form the final string
        const result = capitalizedWords.join(" ");

        return result;
    } catch (e) {
        return string;
    }
};

const scrollOnErrorField = () => {
    const firstErrorField = document.querySelector(".error");
    if (firstErrorField) {
        firstErrorField.scrollIntoView({
            behavior: "smooth",
            block: "center",
        });
    }
}

export default {
    dashedToRegularFormat: dashedToRegularFormat,
    dateFormatter: dateFormatter,
    timeAgo: timeAgo,
    formatter: formatter,
    localTime: localTime,
    onlyLocalTime: onlyLocalTime,
    hasRole: hasRole,
    scrollOnErrorField: scrollOnErrorField,
    can: (permissionName) => {
        let permissions = store.getters["auth/userPermissions"];
        return permissions.indexOf(permissionName) !== -1 || hasRole("admin");
    },
};
