export default {
  namespaced: true,
  state: {
    configuration: {},
  },
  getters: {
    configuration: (state) => state.configuration,
  },
  mutations: {
    configuration: (state, payload) => (state.configuration = payload),
  },
  actions: {
    save({}, payload) {
      return this.$apiService.post("/save-global-configuration", payload);
    },
    saveFiles({}, payload) {
      return this.$apiService.post("/save-global-configuration-files", payload);
    },
    show({ commit }) {
      return this.$apiService
        .get(`/get-global-configuration`)
        .then((response) => {
          commit("configuration", response?.data?.data ?? {});
          return response;
        });
    },
  },
};
